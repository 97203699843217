import { BinanceWalletConnector } from '@pancakeswap/wagmi/connectors/binanceWallet'
import { mainnet, opBNBTestnet, opBNBMainnet } from '@pancakeswap/wagmi/chains'
import { configureChains, createClient } from 'wagmi'
import memoize from 'lodash/memoize'
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'
import { ChainId } from '@pancakeswap/sdk'
import { PUBLIC_NODES } from 'config/nodes'

const CHAINS = [opBNBMainnet, opBNBTestnet]

const mostNodesConfig = Object.values(PUBLIC_NODES).reduce((prev, cur) => {
    return cur.length > prev ? cur.length : prev
}, 0)

export const { provider, chains } = configureChains(
    CHAINS,
    Array.from({ length: mostNodesConfig })
        .map((_, i) => i)
        .map((i) => {
            return jsonRpcProvider({
                rpc: (chain) => {
                    if (process.env.NODE_ENV === 'test' && chain.id === mainnet.id && i === 0) {
                        return { http: 'https://cloudflare-eth.com' }
                    }
                    return PUBLIC_NODES[chain.id]?.[i]
                        ? {
                            http: PUBLIC_NODES[chain.id][i],
                        }
                        : null
                },
            })
        }),
)

export const injectedConnector = new InjectedConnector({
    chains,
    options: {
        shimDisconnect: false,
    },
})

export const coinbaseConnector = new CoinbaseWalletConnector({
    chains,
    options: {
        appName: 'CATO',
        appLogoUrl: '',
    },
})

export const walletConnectConnector = new WalletConnectConnector({
    chains,
    options: {
        showQrModal: !false,
        projectId: 'd46d36e90e4df2f863ddcc6f0e69a3ef',
    },
})

export const walletConnectNoQrCodeConnector = new WalletConnectConnector({
    chains,
    options: {
        showQrModal: false,
        projectId: 'd46d36e90e4df2f863ddcc6f0e69a3ef',
    },
})

export const metaMaskConnector = new MetaMaskConnector({
    chains,
    options: {
        shimDisconnect: false,
    },
})

export const bscConnector = new BinanceWalletConnector({ chains })

export const client = createClient({
    autoConnect: false,
    provider,
    connectors: [
        metaMaskConnector,
        injectedConnector,
        coinbaseConnector,
        walletConnectConnector,
        bscConnector,
    ],
})

export const CHAIN_IDS = chains.map((c) => c.id)

export const isChainSupported = memoize((chainId: number) => CHAIN_IDS.includes(chainId))
export const isChainTestnet = memoize((chainId: number) => chains.find((c) => c.id === chainId)?.testnet)

export const SUPPORT_CHAIN_CLOUDBASE = [ChainId.BASE_MAINNET, ChainId.BASE_GOERLI]
export const SUPPORT_CHAIN_OP = [ChainId.OPTIMISTIC]