import axios from "axios";
import { API_URL } from "./APP_URL";

const getTonWallet = async (params: {bscWallet: string}) => (await axios.get(API_URL.GET_TON_WALLET, { params }))
const getWhitelist = async (params: {bscWallet: string}) => (await axios.get(`${API_URL.GET_WHITE_LIST}/${params.bscWallet}`))


const postAddWallet = async (data: {
    bscWallet: string,
    tonWallet: string,
}) => (await axios.post(API_URL.POST_ADD_WALLET, data))
export const airdropApi = {
    getTonWallet,
    getWhitelist,
    postAddWallet
}