import { useTranslation } from '@pancakeswap/localization'
import styled from 'styled-components'
import {
    Box,
    Flex,
    LogoutIcon,
    RefreshIcon,
    useModal,
    UserMenu as UIKitUserMenu,
    UserMenuDivider,
    UserMenuItem,
    UserMenuVariant,
    LogoIcon
} from '@pancakeswap/uikit'
import { FiLogOut } from "react-icons/fi";
import { FaRegCopy } from "react-icons/fa6";
import ConnectWalletButton from 'components/ConnectWalletButton'
import Trans from 'components/Trans'
import { useActiveChainId } from 'hooks/useActiveChainId'
import useAuth from 'hooks/useAuth'
import { useEffect, useState } from 'react'
import { usePendingTransactions } from 'state/transactions/hooks'
import { useAccount, useBalance } from 'wagmi'
import useNativeCurrency from 'hooks/useNativeCurrency'
import { formatBigNumber } from 'utils/formatBalance'
import WalletUserMenuItem from './WalletUserMenuItem'
import WalletModal, { WalletView } from './WalletModal'
import { NetworkSwitcher } from 'components/NetworkSwitcher'
import { Dropdown, Image, Modal, message } from 'antd'
import { useCopyToClipboard } from "react-use";

const UserMenu = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [_, copyToClipboard] = useCopyToClipboard();


    const { t } = useTranslation()
    const { address: account } = useAccount()
    const { isWrongNetwork } = useActiveChainId()
    const { logout } = useAuth()
    const { hasPendingTransactions, pendingNumber } = usePendingTransactions()
    const [userMenuText, setUserMenuText] = useState<string>('')
    const [userMenuVariable, setUserMenuVariable] = useState<UserMenuVariant>('default')
    const [onPresentWalletModal] = useModal(<WalletModal initialView={WalletView.WALLET_INFO} />)
    const [onPresentTransactionModal] = useModal(<WalletModal initialView={WalletView.TRANSACTIONS} />)
    const [onPresentWrongNetworkModal] = useModal(<WalletModal initialView={WalletView.WRONG_NETWORK} />)
    const avatarSrc = undefined
    const native = useNativeCurrency()
    const nativeBalance = useBalance({ address: account, enabled: !false })
    useEffect(() => {
        if (hasPendingTransactions) {
            setUserMenuText(t('%num% Pending', { num: pendingNumber }))
            setUserMenuVariable('pending')
        } else {
            setUserMenuText('')
            setUserMenuVariable('default')
        }
    }, [hasPendingTransactions, pendingNumber, t])

    const onClickWalletMenu = (): void => {
        if (isWrongNetwork) {
            onPresentWrongNetworkModal()
        } else {
            onPresentWalletModal()
        }
    }
    const UserMenuItems = () => {
        return (
            <>
                <UserMenuItem as="button" onClick={logout}>
                    <Flex alignItems="center" justifyContent="space-between" width="100%">
                        {t('Disconnect')}
                        <LogoutIcon />
                    </Flex>
                </UserMenuItem>
            </>
        )
    }

    if (account) {
        return (
            <>
                <div onClick={() => setIsModalOpen(true)}>
                    <UIKitUserMenu
                        account={account}
                        avatarSrc={avatarSrc}
                        text={userMenuText}
                        variant={userMenuVariable}
                        isShowIcon={false}
                        symbolNative={native?.symbol}
                        nativeBalance={(!nativeBalance?.isFetched || nativeBalance.data === undefined) ? "0" : formatBigNumber(nativeBalance.data.value, 2)}
                    />
                </div>
                <CsModal
                    title=""
                    open={isModalOpen}
                    onCancel={() => setIsModalOpen(false)}
                    footer={false}
                    centered
                    width={400}
                >
                    {
                        account &&
                        <div className=''>
                            <div className='flex justify-center flex-col items-center'>
                                <Image preview={false} width={80} src='/logo.png'/>
                            </div>
                            <div className='grid grid-cols-2 gap-4 mt-5'>
                                <div
                                    onClick={() => {
                                        copyToClipboard(account);
                                        message.success("Copied!");
                                    }}
                                    className='hover:bg-[#ffffff4d] bg-[#ffffff0a] cursor-pointer p-2 flex flex-col gap-2 rounded-md items-center justify-center'>
                                    <FaRegCopy color='#ffff' size={20} />
                                    <p className='text-white font-bold text-[13px]'>Copy Address</p>
                                </div>
                                <div onClick={logout} className='hover:bg-[#ffffff4d] bg-[#ffffff0a] cursor-pointer p-2 flex flex-col gap-2 rounded-md items-center justify-center'>
                                    <FiLogOut color='#ffff' size={20} />
                                    <p className='text-white font-bold text-[13px]'>Disconnect</p>
                                </div>
                            </div>
                        </div>
                    }
                </CsModal>
            </>
        )
    }

    if (isWrongNetwork) {
        return (
            <UIKitUserMenu text={t('Network')} variant="danger">
                {({ isOpen }) => (isOpen ? <UserMenuItems /> : null)}
            </UIKitUserMenu>
        )
    }

    return (
        <>
            <CsConnectWalletButton scale="sm">
                <Box display='block'>
                    <Trans>Connect Wallet</Trans>
                </Box>
            </CsConnectWalletButton>
        </>
    )
}

export default UserMenu

const CsConnectWalletButton = styled(ConnectWalletButton)`
    border-radius: 40px;
    border: 1px solid #EDA426;
    background: #EDA426;
    display: inline-flex;
    padding:0 12px;
    line-height: 44px;
    height: 44px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: white;
`
const CsModal = styled(Modal)`
    .ant-modal-content{
        border-radius: 11.675px;
        background: rgba(255, 255, 255, 0.04);
        box-shadow: 0px 0px 1.5px 0px rgba(255, 255, 255, 0.30) inset;
        backdrop-filter: blur(20px);
    }
    .ant-modal-close-icon{
        color: #ffff;
    }
`