import { ChainId } from '@pancakeswap/sdk'
import { opBNBMainnet, opBNBTestnet } from '@pancakeswap/tokens'

export const renderTokenAlTheNaUSDT = (chainId: number) => {
    if (chainId === ChainId.OPBNB_TESTNET) {
        return opBNBTestnet.usdt
    }
    return opBNBMainnet.usdt
}

export const renderTokenAlTheUSDB = (chainId: number) => {
    if (chainId === ChainId.OPBNB_TESTNET) {
        return opBNBTestnet.usdb
    }
    return opBNBMainnet.usdt
}

export const getTokenSUSDB = (chainId: number) => {
    if( chainId === ChainId.OPBNB_TESTNET) {
        return opBNBTestnet.susdb
    }
    return opBNBTestnet.susdb
}