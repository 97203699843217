import { createAsyncThunk } from "@reduxjs/toolkit"
import { fetchTonWallet, fetchWhitelist } from "./fetch_data"


export const fetchTonWalletByBscWallet = createAsyncThunk<any, { bscWallet: string }>(
    'airdrop/fetchTonWalletByBscWallet',
    async ({ bscWallet }) => {
        const resData = await fetchTonWallet(bscWallet)
        return resData
    }
)

export const fetchIsWhitelist = createAsyncThunk<any, { bscWallet: string }>(
    'airdrop/fetchIsWhitelist',
    async ({ bscWallet }) => {
        const resData = await fetchWhitelist(bscWallet)
        return resData
    }
)
