import { ChainId, Token } from '@pancakeswap/sdk'

export const opBNBTestnet = {
    usdt: new Token(
        ChainId.OPBNB_TESTNET,
        '0x799C7692010919983F0a6916bAfE1981054F314E',
        18,
        'USDT',
        'USDT Token',
        'https://tether.to/',
    ),
    usdb: new Token(
        ChainId.OPBNB_TESTNET,
        '0x9449ff3E4d658018e183FD5aF304c14913bD8c25',
        18,
        'USDb',
        'USDb Token',
        'https://tether.to/',
    ),
    susdb: new Token(
        ChainId.OPBNB_TESTNET,
        '0x115066a4CCCCc42055147F94C38126A54f2F5fda',
        18,
        'SUSDB',
        'SUSDB Token',
        '',
    ),
}

