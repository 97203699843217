import { createSlice } from '@reduxjs/toolkit';
import { InitialState } from './type';
import { fetchTonWalletByBscWallet, fetchIsWhitelist } from './action';


const initialState: InitialState = {
    tonWallet: "",
    isWhitelist: false

}
export const airdropSlice = createSlice({
    name: "cycle",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchTonWalletByBscWallet.fulfilled, (state, { payload }) => {
                state.tonWallet = payload.tonWallet
            })
        builder
            .addCase(fetchIsWhitelist.fulfilled, (state, { payload }) => {
                state.isWhitelist = payload.isWhitelist
            })

    }
});
export default airdropSlice.reducer
