import { airdropApi } from "config/api/airdrop"


export const fetchTonWallet = async (bscWallet: string): Promise<{ tonWallet: string }> => {
    if( !bscWallet ) {
        return { tonWallet: "" }
    }
    try {
        const res = await airdropApi.getTonWallet({ bscWallet })
        return { tonWallet: res?.data }
    } catch (error) {
        console.log("Can not fetch ton wallet", error)
        return {
            tonWallet: ""
        }
    }
}

export const fetchWhitelist = async (bscWallet: string): Promise<{ isWhitelist: boolean }> => {
    if( !bscWallet ) {
        return { isWhitelist: false }
    }
    try {
        const res = await airdropApi.getWhitelist({ bscWallet })
        return { isWhitelist: res?.data }
    } catch (error) {
        console.log("Can not fetch whitelist", error)
        return { isWhitelist: false }
    }
}