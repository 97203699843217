import { useTranslation } from '@pancakeswap/localization'
import { Button, Logo, Menu as UikitMenu } from '@pancakeswap/uikit'
import { NetworkSwitcher } from 'components/NetworkSwitcher'
import { NextLinkFromReactRouter } from 'components/NextLink'
import { useRouter } from 'next/router'
import { createContext, useMemo, useState } from 'react'
import styled from 'styled-components'
import { footerLinks } from './config/footerConfig'
import { useMenuItems } from './hooks/useMenuItems'
import UserMenu from './UserMenu'
import { getActiveMenuItem, getActiveSubMenuItem } from './utils'
import { BuyCloud } from './BuyCloud'
import { BsThreeDotsVertical } from "react-icons/bs"
import { AiOutlineClose } from "react-icons/ai"
import { Drawer } from 'antd'
import Link from 'next/link'
import clsx from 'clsx'



const RefreshCreate = []

export const RefreshCreateGlobal = createContext(RefreshCreate)

const Menu = (props) => {
    const { t } = useTranslation()
    const { pathname, push } = useRouter()
    const [open, setOpen] = useState(false);

    const menuItems = useMenuItems()

    const activeMenuItem = getActiveMenuItem({ menuConfig: menuItems, pathname })
    const activeSubMenuItem = getActiveSubMenuItem({ menuItem: activeMenuItem, pathname })
    const getFooterLinks = useMemo(() => {
        return footerLinks(t)
    }, [t])
    return (
        <>
            <UikitMenu
                linkComponent={(linkProps) => {
                    return <NextLinkFromReactRouter to={linkProps.href} {...linkProps} prefetch={false} />
                }}
                rightSide={
                    <>
                        <UserMenu />
                    </>
                }
                links={menuItems}
                footerLinks={getFooterLinks}
                activeItem={activeMenuItem?.href}
                activeSubItem={activeSubMenuItem?.href}
                {...props}
            />
        </>
    )
}

export default Menu
