import type { ThemeConfig } from 'antd';

export const themeAntd: ThemeConfig = {
    components: {
        Radio: {
            radioSize: 20
        },
        Checkbox: {
            controlInteractiveSize: 20
        },
        Button: {
            colorPrimary: "#EDA426",
            colorPrimaryHover: "#EDA426",
            colorBgContainerDisabled:"#3D3D3D",
            colorTextDisabled: "white",
            colorBorder: "transparent"
        }
    },
};